import axios from "axios";
import { toast } from "react-toastify";
import { confirmOrderProps } from "../interface";



const confirmOrder = async (state: confirmOrderProps) => {  
  console.log("state", state)
  const config = {
    method: "post",
    url: `${process.env.BACKEND_URL}/api/confirm_payment`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      paymentIntentId: state.paymentIntentId,
      email: state.email,
      userName: state.userName,
      serviceId: state.serviceId,
      link: [`${state.type === "follower" ? "": state.link.map((item:any) => item.url)}`],
      amount: state.amount,
      quantity: state.quantity,
      type: state.type,
      offer: state.offer,
    },
  };


  try {
    const checkout = await axios(config);
    return checkout;
  } catch (error: any) {
   
    toast.error(error.message);
  }
};

export default confirmOrder;
